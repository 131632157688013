// @flow

import { universalBtoa } from '../../helpers/idTools'

type BaseCottageLegendEntry = {
  name: string,
  streetNumber: string,
}

type CottageLegendEntry = {
  id: string,
  name: string,
  streetNumber: string,
  streetName: string,
}

const createId = ({ name, streetNumber }: BaseCottageLegendEntry) =>
  universalBtoa(`cottage:${name.toLowerCase()}:${streetNumber}`)

const addIdAndStreetName = ({
  name,
  streetNumber,
}: BaseCottageLegendEntry) => ({
  id: createId({ name, streetNumber }),
  name,
  streetNumber,
  streetName: 'Estero Boulevard',
})

export const cottages: CottageLegendEntry[] = [
  { name: 'Gulf', streetNumber: '466' },
  { name: 'Sun', streetNumber: '464' },
  { name: 'Mermaid', streetNumber: '462' },
  { name: 'Coconut', streetNumber: '460' },
  { name: 'Island', streetNumber: '454' },
  { name: 'Seahorse', streetNumber: '452' },
  { name: 'Dolphin', streetNumber: '436' },
].map(addIdAndStreetName)

export default cottages
