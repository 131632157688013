// @flow

import React from 'react'
import type { Node } from 'react'

function CottageVisual(): Node {
  return (
    <table
      style={{
        marginLeft: '-0.9pt',
        borderCollapse: 'collapse',
      }}
      border="0"
      width="397"
      cellSpacing="0"
      cellPadding="0"
    >
      <tbody>
        <tr style={{ height: '.4in' }}>
          <td
            style={{
              width: '200.05pt',
              borderTop: 'solid windowtext 1.0pt',
              borderLeft: 'solid windowtext 1.0pt',
              borderBottom: 'none',
              borderRight: 'solid white 1.0pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '.4in',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            colSpan="5"
            rowSpan="3"
            nowrap="nowrap"
            width="333"
          >
            <p style={{ textAlign: 'center' }} align="center">
              <span>BEACH</span>
            </p>
          </td>
          <td
            style={{ height: '.4in', border: 'none' }}
            width="0"
            height="48"
          />
        </tr>
        <tr style={{ height: '.2in' }}>
          <td
            style={{ height: '.2in', border: 'none' }}
            width="0"
            height="24"
          />
        </tr>
        <tr style={{ height: '15.0pt' }}>
          <td
            style={{ height: '15.0pt', border: 'none' }}
            width="0"
            height="25"
          />
        </tr>
        <tr style={{ height: '43.8pt' }}>
          <td
            style={{
              width: '66.15pt',
              border: 'solid windowtext 1.0pt',
              background: '#B2A1C7',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '43.8pt',
              borderColor: 'white',
            }}
            valign="bottom"
            width="110"
          >
            <p style={{ textAlign: 'center' }} align="center">
              <span style={{ color: 'white' }}>Gulf Cottage (Violet)</span>
            </p>
          </td>
          <td
            style={{
              width: '17.4pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '43.8pt',
              borderColor: 'white',
            }}
            rowSpan="7"
            nowrap="nowrap"
            width="29"
          >
            <p style={{ textAlign: 'center' }} align="center">
              <span style={{ color: 'white' }}>DRIVEWAY</span>
            </p>
          </td>
          <td
            style={{
              width: '51.25pt',
              border: 'solid windowtext 1.0pt',
              background: '#FF3300',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '43.8pt',
              borderColor: 'white',
            }}
            valign="bottom"
            width="85"
          >
            <p style={{ textAlign: 'center' }} align="center">
              <span>My Home (Coral)</span>
            </p>
          </td>
          <td
            style={{
              width: '47.85pt',
              border: 'solid windowtext 1.0pt',
              borderLeft: 'none',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '43.8pt',
              borderColor: 'white',
            }}
            width="80"
          >
            <p align="center">
              <span style={{ color: 'white' }}>Private Home</span>
            </p>
          </td>
          <td
            style={{
              width: '17.4pt',
              border: 'none',
              borderRight: 'solid windowtext 1.0pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '43.8pt',
              borderColor: 'white',
            }}
            rowSpan="7"
            nowrap="nowrap"
            width="29"
          >
            <p align="center">
              <span style={{ color: 'white' }}>DRIVEWAY</span>
            </p>
          </td>
          <td
            style={{ height: '43.8pt', border: 'none', borderColor: 'white' }}
            width="0"
            height="73"
          />
        </tr>
        <tr style={{ height: '15.0pt' }}>
          <td
            className="_space"
            style={{
              width: '66.15pt',
              borderTop: 'none',
              borderLeft: 'solid windowtext 1.0pt',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'none',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="110"
          />
          <td
            style={{
              width: '51.25pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="85"
          />
          <td
            className="_space"
            style={{
              width: '47.85pt',
              borderTop: 'none',
              borderLeft: 'solid windowtext 1.0pt',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'none',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="80"
          />
          <td
            style={{
              height: '15.0pt',
              border: 'none',
              borderColor: 'white',
            }}
            width="0"
            height="25"
          />
        </tr>
        <tr style={{ height: '.6in' }}>
          <td
            style={{
              width: '66.15pt',
              border: 'solid windowtext 1.0pt',
              borderTop: 'none',
              background: 'yellow',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '.6in',
              borderColor: 'white',
            }}
            valign="bottom"
            width="110"
          >
            <p align="center">
              <span style={{ color: 'rgb(47, 47, 1)' }}>
                Sun Cottage (Yellow)
              </span>
            </p>
          </td>
          <td
            style={{
              width: '51.25pt',
              border: 'solid windowtext 1.0pt',
              background: '#92D050',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '.6in',
              borderColor: 'white',
            }}
            valign="bottom"
            width="85"
          >
            <p align="center">
              <span style={{ color: 'white' }}>Island Cottage (Green)</span>
            </p>
          </td>
          <td
            style={{
              width: '47.85pt',
              borderTop: 'none',
              borderLeft: 'none',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'solid windowtext 1.0pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '.6in',
              borderColor: 'white',
            }}
            width="80"
          >
            <p align="center">
              <span style={{ color: 'white' }}>Private Home</span>
            </p>
          </td>
          <td
            style={{
              height: '.6in',
              border: 'none',
              borderColor: 'white',
            }}
            width="0"
            height="72"
          />
        </tr>
        <tr style={{ height: '15.0pt' }}>
          <td
            className="_space"
            style={{
              width: '66.15pt',
              borderTop: 'none',
              borderLeft: 'solid windowtext 1.0pt',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'none',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="110"
          />
          <td
            className="_space"
            style={{
              width: '51.25pt',
              border: 'none',
              borderBottom: 'solid windowtext 1.0pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="85"
          />
          <td
            className="_space"
            style={{
              width: '47.85pt',
              borderTop: 'none',
              borderLeft: 'solid windowtext 1.0pt',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'none',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="80"
          />
          <td
            style={{ height: '15.0pt', border: 'none', borderColor: 'white' }}
            width="0"
            height="25"
          />
        </tr>
        <tr style={{ height: '43.8pt' }}>
          <td
            style={{
              width: '66.15pt',
              border: 'solid windowtext 1.0pt',
              borderTop: 'none',
              background: '#8064A2',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '43.8pt',
              borderColor: 'white',
            }}
            valign="bottom"
            width="110"
          >
            <p align="center">
              <span style={{ color: 'white' }}>Mermaid Cottage (Purple)</span>
            </p>
          </td>
          <td
            style={{
              width: '51.25pt',
              border: 'solid windowtext 1.0pt',
              borderTop: 'none',
              background: '#FFC000',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '43.8pt',
              borderColor: 'white',
            }}
            valign="bottom"
            width="85"
          >
            <p align="center">
              <span style={{ color: 'white' }}>Seahorse Cottage (Orange)</span>
            </p>
          </td>
          <td
            style={{
              width: '47.85pt',
              borderTop: 'none',
              borderLeft: 'none',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'solid windowtext 1.0pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '43.8pt',
              borderColor: 'white',
            }}
            width="80"
          >
            <p align="center">
              <span style={{ color: 'white' }}>Private Home</span>
            </p>
          </td>
          <td
            style={{
              height: '43.8pt',
              border: 'none',
              borderColor: 'white',
            }}
            width="0"
            height="73"
          />
        </tr>
        <tr style={{ height: '15.0pt' }}>
          <td
            className="_space"
            style={{
              width: '66.15pt',
              borderTop: 'none',
              borderLeft: 'solid windowtext 1.0pt',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'none',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="110"
          />
          <td
            className="_space"
            style={{
              width: '51.25pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="85"
          />
          <td
            className="_space"
            style={{
              width: '47.85pt',
              borderTop: 'none',
              borderLeft: 'solid windowtext 1.0pt',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'none',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '15.0pt',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            valign="bottom"
            nowrap="nowrap"
            width="80"
          />
          <td
            style={{
              height: '15.0pt',
              border: 'none',
              borderColor: 'white',
            }}
            width="0"
            height="25"
          />
        </tr>
        <tr style={{ height: '.6in' }}>
          <td
            style={{
              width: '66.15pt',
              border: 'solid windowtext 1.0pt',
              borderTop: 'none',
              background: 'red',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '.6in',
              borderColor: 'white',
            }}
            valign="bottom"
            width="110"
          >
            <p align="center">
              <span style={{ color: 'white' }}>
                Coconut Cottage (Strawberry)
              </span>
            </p>
          </td>
          <td
            style={{
              width: '51.25pt',
              border: 'solid windowtext 1.0pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '.6in;',
              borderColor: 'white',
            }}
            width="85"
          >
            <p align="center">
              <span style={{ color: 'white' }}>Private Home</span>
            </p>
          </td>
          <td
            style={{
              width: '47.85pt',
              borderTop: 'none',
              borderLeft: 'none',
              borderBottom: 'solid windowtext 1.0pt',
              borderRight: 'solid windowtext 1.0pt',
              background: '#93CDDD',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '.6in',
              borderColor: 'white',
            }}
            width="80"
          >
            <p align="center">
              <span style={{ color: 'white' }}>Dolphin Cottage (Blue)</span>
            </p>
          </td>
          <td
            style={{ height: '.6in', border: 'none', borderColor: 'white' }}
            width="0"
            height="72"
          />
        </tr>
        <tr style={{ height: '.2in' }}>
          <td
            className="_space"
            style={{
              width: '200.05pt',
              borderTop: 'none',
              borderLeft: 'solid windowtext 1.0pt',
              borderBottom: 'solid white 1.0pt',
              borderRight: 'solid white 1.0pt',
              padding: '0in 5.4pt 0in 5.4pt',
              height: '.2in',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            colSpan="5"
            rowSpan="2"
            valign="bottom"
            nowrap="nowrap"
            width="333"
          />
          <td
            style={{ height: '.2in', border: 'none', borderColor: 'white' }}
            width="0"
            height="24"
          />
        </tr>
        <tr style={{ height: '15.0pt' }}>
          <td
            style={{
              height: '15.0pt',
              border: 'none',
              borderColor: 'white',
              backgroundColor: '#6d5cb6',
            }}
            width="0"
            height="25"
          />
        </tr>
      </tbody>
    </table>
  )
}

export default CottageVisual
