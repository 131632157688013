// @flow

import * as React from 'react'

import Layout from '../components/layout'
import { Heading1, Heading4, BodyText } from '../components/typography'
import { List } from '../components'
import CottageVisual from '../scenes/cottage-visual/cottage-visual'
import { cottages } from '../data/cottage-legend'

type QuestionHeadingProps = {
  children: React.Node,
}

type AnswerTextProps = {
  children: React.Node,
}

function QuestionHeading({ children }: QuestionHeadingProps) {
  return <Heading4>Q: {children}</Heading4>
}

function AnswerText({ children }: AnswerTextProps) {
  return <BodyText className="text-purple-lightest">A: {children}</BodyText>
}

function FaqPage(): React.Node {
  return (
    <Layout>
      <div className="bg-purple text-white">
        <div className="pt-12 pb-2 md:pt-16 px-8">
          <div className="w-full md:max-w-md md:mx-auto">
            <div className="mb-6">
              <Heading1 isTitle className="mb-5">
                FAQ’s
              </Heading1>
              <ul className="leading-normal">
                <li className="mb-3">
                  <QuestionHeading>
                    Do you have wireless internet?
                  </QuestionHeading>
                  <AnswerText>
                    Yes we do! Each cottage has its own password protected
                    wireless network.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    How far is my cottage from the Fort Myers Airport (RSW)?
                  </QuestionHeading>
                  <AnswerText>
                    Cottages of Paradise Point is 20-30 minutes from the Fort
                    Myers Airport (RSW).
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    Where on Fort Myers Beach is Cottages of Paradise Point? How
                    far from the Times Square area and the Fort Myers Beach
                    fishing pier?
                  </QuestionHeading>
                  <AnswerText>
                    Cottages of Paradise Point is on the north end of Fort Myers
                    Beach about 4 blocks from the Times Square area and the Fort
                    Myers Beach fishing pier.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>Is there a swimming pool?</QuestionHeading>
                  <AnswerText>
                    There is a swimming pool between the Gulf and Sun Cottages
                    that you have been invited to use. Use of this pool is not
                    included in your rent. The owners of the pool have invited
                    you to use it as their guest. This invitation is extended
                    only to guests registered in your cottage. All the owners of
                    the pool ask is that you and all members of your party
                    behave responsibly, follow the rules as posted, and sign the
                    waiver included in your welcome package. Your invitation to
                    use the pool is contingent on signing the waiver.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    Can I get mid-week cleanings?
                  </QuestionHeading>
                  <AnswerText>
                    Yes you can. Just ask Leisure American about it.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    Will my cottage have a grill? Washing machine/clothes dryer?
                  </QuestionHeading>
                  <AnswerText>
                    Every cottage has a propane grill, as well as its own washer
                    and dryer.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    How far from the beach is my cottage?
                  </QuestionHeading>
                  <AnswerText>Each cottage is steps from the beach.</AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    I&#8217;m having trouble figuring out the layout of the
                    cottages in relation to each other?
                  </QuestionHeading>
                  <AnswerText>
                    Here is a drawing of the cottage layout. It is not to scale
                    and does not accurately reflect what the view from each
                    cottage is. For that look at the individual cottage
                    pictures. There is a picture of the view from each cottage
                    included.
                  </AnswerText>
                  <div className="py-4">
                    <CottageVisual />
                  </div>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    What does my view look like?
                  </QuestionHeading>
                  <AnswerText>
                    We have included a picture of the view from each cottage
                    along with the other pictures of each cottage on the
                    website.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    Do you have cribs, playpens and strollers available?
                  </QuestionHeading>
                  <AnswerText>
                    Cribs, playpens, strollers as well as bicycles and beach
                    equipment are available to rent from Happy Baby Rentals.
                    Their website is www.happybabyrentals.com. Their phone
                    number is 239-765-7368 and their email is
                    happybabyrentals@hotmail.com. Be sure to tell them you are
                    renting a cottage at Cottages of Paradise Point.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    What is the address of my cottage?
                  </QuestionHeading>
                  <AnswerText>
                    <span>
                      All cottages are located in Fort Myers Beach, FL 33931
                    </span>
                    {cottages && cottages.length > 0 ? (
                      <List className="mt-3">
                        {cottages.map(
                          ({ id, name, streetNumber, streetName }) => (
                            <li key={id}>
                              {name} Cottage is {streetNumber} {streetName}.
                            </li>
                          ),
                        )}
                      </List>
                    ) : null}
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    Do I need to bring beach towels?
                  </QuestionHeading>
                  <AnswerText>
                    Nope – each cottage has a full supply of beach towels for
                    you and your family to use.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    Do I need to bring beach lounges or chairs?{' '}
                  </QuestionHeading>
                  <AnswerText>
                    There is deck furniture on each cottage’s deck and at the
                    swimming pool. This furniture stays in place on the deck.
                    There are also lounge chairs on the beach for the use of our
                    guests. Many guests bring their own favorite beach lounges
                    or chairs, and others purchase inexpensive furniture on the
                    beach which they often leave behind when they leave. We
                    leave these “left-behinds” under the cottages for other
                    guest’s use until they finally break and get thrown away.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>What about beach umbrellas?</QuestionHeading>
                  <AnswerText>
                    We provide an umbrella stand on your deck, but do not supply
                    umbrellas. Inexpensive umbrellas can be purchased on the
                    beach. Most folks leave these inexpensive umbrellas behind
                    when they depart, and and we leave them out for other
                    guest&#8217;s use until they finally break and get thrown
                    away.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>What do I need to bring?</QuestionHeading>
                  <AnswerText>
                    Groceries, clothes, bathing suits, and sunscreen. Don’t
                    forget the kids.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    What about paper products and soap?{' '}
                  </QuestionHeading>
                  <AnswerText>
                    We get you started with a starter supply of toilet paper,
                    paper towels, garbage bags, soap, etc.
                  </AnswerText>
                </li>
                <li className="mb-3">
                  <QuestionHeading>
                    Does my cottage have a washer and dryer?
                  </QuestionHeading>
                  <AnswerText>
                    Each cottage has its own washer and dryer.
                  </AnswerText>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default FaqPage
